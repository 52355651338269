const menu = [
    {
        index: 1,
        id: "section1",
        name: "Service Introduction",
        visible: true,
        url: "/",
    },
    {
        index: 2,
        id: "section2",
        name: "Consultation AI Portal",
        visible: true,
        url: "/consultation-portal",
    },
    {
        index: 3,
        id: "section3",
        name: "App Package",
        visible: false,
        url: "/",
    },
    {
        index: 4,
        id: "section4",
        name: "Flywheel model",
        visible: false,
        url: "/",
    },
    {
        index: 5,
        id: "section5",
        name: "Teacher's App",
        visible: true,
        url: "/teachers-app",
    },
    {
        index: 6,
        id: "section6",
        name: "User Flow",
        visible: false,
        url: "/",
    },
    {
        index: 7,
        id: "section7",
        name: "Color System",
        visible: false,
        url: "/",
    },
    {
        index: 8,
        id: "section8",
        name: "Overview",
        visible: false,
        url: "/",
    },
    {
        index: 9,
        id: "section9",
        name: "Design Guidelines",
        visible: false,
        url: "/",
    },
];

export default menu;
